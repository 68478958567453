import React from "react";
import { ReactComponent as LogoIcon } from "../../../images/new_logo_white.svg";
import { ReactComponent as LogoSkolkovoIcon } from "../../../images/logo_skolkovo.svg";
import { ReactComponent as VisaIcon } from "../../../images/visa.svg";
import { ReactComponent as QiwiIcon } from "../../../images/qiwi.svg";
import { ReactComponent as MirIcon } from "../../../images/mir.svg";
import { ReactComponent as MastercardIcon } from "../../../images/mastercard.svg";
import { ReactComponent as TgIcon } from "../../../images/tg.svg";

import { Link } from "react-router-dom";
import { CONTACTS } from "../../../constants";
import "./Footer.scss";

const Footer = ({ isMobile, isTablet }) => {
  const { baseUrl, authUrlForSupplier } = global.config;

  const contacts = {
    phone: CONTACTS.PHONE.nsk,
    phone_text: CONTACTS.PHONE_TEXT.nsk
  }
  
  const address = <p className="address">
    ООО «РАМ-Дистрибуция», ОГРН 1215400019845, <br /> ИНН 5433977525, 633102, Новосибирская область, Обь городской округ, нп. 3307 км, 30кВ1
    Разработчик платформы - ООО «РАМ-ИТ».
  </p>;

  const copyrights = "«rusagro.market»"

  const renderCopyrights = () => (
    <div className="copyrights">
      <div>Copyright © {copyrights} Все права защищены</div>
    </div>
  );

  const renderCopyrightsMobile = () => (
    <div className="copyrights">
      <div>Copyright © {copyrights}</div>
      <div> Все права защищены</div>
    </div>
  );

  return (
    <div className="footer">
      <div className="container">
        <div className="block rows line">
          <div className="left-block">
            <div className="row1 l1">
              <div className="logos">
                <a className="logo" href="/">
                  {<LogoIcon />}
                </a>
                <a className="logo skolkovo" href="https://navigator.sk.ru/orn/1122708" target="_blank" rel="noopener noreferrer">
                  <LogoSkolkovoIcon />
                </a>
              </div>
              {/* {isTablet && (
                <div className="social">
                  <a href="#">
                    <FbIcon />
                  </a>
                  <a href="#">
                    <InstaIcon />
                  </a>
                  <a href="#">
                    <TwitterIcon />
                  </a>
                </div>
              )} */}
            </div>
            <div className="left-block-menu">
              <div className="row2 l2">
                <h3>Покупателю</h3>
                <Link to="/help/2/0">Доставка</Link>
                <Link to="/help/3/0">Оплата</Link>
                <Link to="/help/3/2">Возвраты</Link>
                <Link to="/help">Помощь</Link>
              </div>
              <div className="row2 l3">
                <h3>О компании</h3>
                <Link to="/help/0/3">Реквизиты</Link>
                <Link to="/contacts">Контакты</Link>

                <a href={authUrlForSupplier} target="_blank" rel="noopener noreferrer">
                  Вход для поставщиков
                </a>
              </div>
            </div>
          </div>
          <div className="right-block">
            <div className="right-block-content">
              <div className="phone-info">
                <a href={`tel: ${contacts.phone}`}>{contacts.phone_text}</a>
                <div>{`пн-пт с 9:00 до 18:00 по нск`}</div>
              </div>

              <div className="footer-messenger-icons">
                {/* <a className="footer-messenger-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.WHATSAPP}>
                  <WhatsappIcon />
                </a> */}
                <a className="footer-messenger-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
                  <TgIcon />
                </a>
              </div>
            </div>
            {address}
          </div>
          {/* {!isMobile && (
          <div className="right-block">
            <h3>Приложение для закупок</h3>
            <div className="social">
              <a href="#">
                <AppleIcon />
              </a>
              <a href="#">
                <AndroidIcon />
              </a>
            </div>
          </div>
        )} */}
        </div>
        <div className="block rows second">
          {!isMobile && !isTablet && renderCopyrights()}

          {(isMobile || isTablet) && (
            <div className="footer-credit-cards">
              <div className="credit-card-logo">
                <QiwiIcon />
              </div>
              <div className="credit-card-logo visa">
                <VisaIcon />
              </div>
              <div className="credit-card-logo">
                <MastercardIcon />
              </div>
              <div className="credit-card-logo">
                <MirIcon />
              </div>
            </div>
          )}
          <div className="rows-content">
            <div className="policy">
              <a href={`${baseUrl}/license_agreement/`} target="_blank" rel="noopener noreferrer">
                Пользовательского соглашения
              </a>
              <a href={`${baseUrl}/privacy_policy/`} target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
              {(isMobile || isTablet) && renderCopyrightsMobile()}
            </div>
            {!isMobile && !isTablet && (
              <div className="footer-credit-cards">
                <div className="credit-card-logo">
                  <QiwiIcon />
                </div>
                <div className="credit-card-logo visa">
                  <VisaIcon />
                </div>
                <div className="credit-card-logo">
                  <MastercardIcon />
                </div>
                <div className="credit-card-logo">
                  <MirIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

